import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as l from 'lodash';
import Navigator from '../components/navigator';
import Layout from '../components/layout';
import AuthenticatedPage from '../components/authenticated-page';
import { checkJWTExpired, getToken, getRoleId, navigate } from '../utils/utils';
import { RoleId } from '../constants';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

export default function ReviewCreatedVasp({ location }) {
  const token = getToken();
  if (!token || checkJWTExpired(token) || !location || !location.state) {
    // push back to home if not logged in or token expired
    navigate('/');
  }

  const vaspRoleId = getRoleId(token);
  if (
    (vaspRoleId !== RoleId.ACCOUNT_MANAGER && vaspRoleId !== RoleId.ADMIN) ||
    !location ||
    !location.state
  ) {
    navigate('/vasp-list');
  }
  const vaspDetail = l.get(location, ['state', 'rowData'], {});

  const classes = useStyles();

  return (
    <Layout>
      <AuthenticatedPage>
        <Navigator
          breadcrumbTexts={['Home', 'Vasp List', 'Review Created Vasp']}
          breadcrumbLinks={['/', '/vasp-list', '/review-created-vasp']}
        />
        <br />
        <div className={classes.container}>
          <form noValidate>
            <div>
              <TextField
                id="email"
                label="Registered Email"
                style={{ margin: 8, width: 400 }}
                margin="normal"
                name="vasp_email"
                type="email"
                disabled
                value={vaspDetail.vasp_email}
              />
            </div>

            <div>
              <TextField
                id="floating-label-required-error-text-field"
                label="Registered Company Name"
                style={{ margin: 8, width: 400 }}
                margin="normal"
                name="vasp_name"
                disabled
                value={vaspDetail.vasp_name}
              />
            </div>

            <div>
              <TextField
                id="filled-number"
                label="Registration Link Expiration"
                style={{ margin: 8, width: 400 }}
                margin="normal"
                name="token_expires_in"
                value={vaspDetail.token_expires_in}
                InputProps={{
                  endAdornment: <InputAdornment position="end">days</InputAdornment>,
                }}
                disabled
                type="number"
              />
            </div>
          </form>
        </div>
      </AuthenticatedPage>
    </Layout>
  );
}

ReviewCreatedVasp.defaultProps = {
  location: undefined,
};

ReviewCreatedVasp.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      parameter: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  }),
};
